import React from "react"
import Img from "gatsby-image"


import { useStaticQuery, graphql } from "gatsby"
import _get from "lodash/get";
import "./style.scss";

const CSInstagramFeed = () => {
  const data = useStaticQuery(graphql`
  query InstagramQuery {
    allInstagramContent {
      edges {
        node {
          localImage {
            
            childImageSharp {
              fixed(width: 260, height: 260) {
                ...GatsbyImageSharpFixed
              }
          }
          
        }
        id
        permalink
      }
    }
  }
}
`)
  let arrayOfInstaImages = _get(data, 'allInstagramContent.edges')

  return (
   
      <div class="container-fluid cs-bundle-box">
        <section id="wrap-insta-feed" class="cf">
            <div class="instagram-hashtag-title">
                <h3>#carrotandstickofficial</h3>
                <p class="instadescDesktop">Show us how you use our products in your skincare routine<br/>and tag #carrotandstickofficial for your chance to be featured!</p>
                <p class="instadescMobile">Show us how you are using our products & tag #carrotandstickofficial for a chance to be featured!</p>
                <div class="igfeeds-custom">
                <div id="sb_instagram" class="sbi sbi_mob_col_2 sbi_tab_col_3 sbi_col_5 sbi_width_resp sbi_palette_light" data-feedid="*2" data-res="auto" data-cols="5" data-colsmobile="2" data-colstablet="3" data-num="20" data-nummobile="20" data-postid="4959" data-locatornonce="2ed4b9dab8" data-sbi-index="1">
                <div id="sbi_images" class="sbi_carousel sbi-owl-loaded sbi-owl-drag" role="carousel">
		            <div class="sbi-owl-stage-outer">
                  <div class="sbi-owl-stage">

                {arrayOfInstaImages.slice(0,5).map((item, i) => {
                    return (
                      <div class="sbi-owl-item cloned" key={i} width="266.2px">
                        <a href={item.node.permalink}>
                        <Img fixed={item.node.localImage.childImageSharp.fixed} />
                        </a>
                      </div>)
                  })}
                  </div>
                  </div>
                  </div>
                </div>
                </div>
                <div class="followbtn">
                    <a class="btn global-orange-cta" href="https://www.instagram.com/carrotandstickofficial/" target="_blank">
                                    Follow Carrot & Stick
                                </a>
                </div>
                
            </div>
        </section>
    </div>

    )
}


export default CSInstagramFeed